import React from 'react';
import parse from 'html-react-parser';

import './message.css';

export default function Message({ message }) {
  return (
    <div className="formMessage">
      <p style={{ fontSize: `1rem` }}>{parse(message)}</p>
    </div>
  );
}
